import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import http from '../_api/http'
import { FileUpload } from '../_api/ajax'
// import ctabg1 from "../assets/images/Careers.jpeg";
// import ctabg2 from "../assets/images/teachers.jpg"
const CareersAtSAS = () => {
  const [values, setValues] = useState({
    fullname: '',
    email: '',
    phone_number: '',
    message: ''
  })

  const [pdffile, setPDF] = useState(null)

  const [validations, setValidations] = useState({
    fullname: '',
    email: '',
    phone_number: '',
    message: ''
  })

  const clearData = () => {
    setValues({
      fullname: '',
      email: '',
      phone_number: '',
      message: '',
      pdf:'',
    })
  }

  const validateAll = () => {
    const { fullname, email, phone_number, message } = values
    const validations = {
      fullname: '',
      email: '',
      phone_number: '',
      message: ''
    }
    let isValid = true

    if (!fullname) {
      validations.fullname = 'Fullname is required'
      isValid = false
    }

    if (!email) {
      validations.email = 'Email is required'
      isValid = false
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = 'Email format must be as example@mail.com'
      isValid = false
    }

    if (!phone_number) {
      validations.phone_number = 'Phone Number is required'
      isValid = false
    }

    // if (phone_number && phone_number.length < 10 || phone_number.length > 10) {
    //   validations.phone_number = 'Phone Number must contain 10 digits'
    //   isValid = false
    // }

    if (!message) {
      validations.message = 'Detail is required'
      isValid = false
    }

    if (!pdffile) {
      validations.pdf = 'Pdf is required'
      isValid = false
    }

    // if (pdffile && !/([a-zA-Z0-9\s_\\.\-\(\):])+(.doc|.docx|.pdf)$/.test(pdf)) {
    //   validations.pdf = 'Pdf format must be .doc,.docx and .pdf'
    //   isValid = false
    // }

    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const fileChanges = e => {
    const { name, files } = e.target
    if (files && files.length) {
      setPDF(files[0])
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('fullname', fullname)
    formData.append('email', email)
    formData.append('phone_number', phone_number)
    formData.append('message', message)
    formData.append('pdf', pdffile)

    const isValid = validateAll()

    if (!isValid) {
      return false
    }
    FileUpload('career/store', formData)
      .then(res => {
        alert('Pdf File Succesfully Uploaded')
        clearData();
      })
      .catch(e => {
        alert(e)
      })
  }

  const { fullname, email, phone_number, message } = values

  const validateOne = e => {
    const { name } = e.target
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
    const value = values[name]
    let message = ''

    if (!value) {
      message = `${name} is required`
    }

    // if (value && name === 'phone_number' && !/[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)) {
    //   message = 'Phone Number must contain 10 number digits'
    // }

    if (value && name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      message = 'Email format must be as example@mail.com'
    }

    if (
      value &&
      name === 'pdf' &&
      !/([a-zA-Z0-9\s_\\.\-\(\):])+(.doc|.docx|.pdf)$/.test(value)
    ) {
      message = 'Pdf format must be .doc,.docx and .pdf'
    }

    setValidations({ ...validations, [name]: message })
  }

  const {
    fullname: fullnameVal,
    email: emailVal,
    phone_number: numberVal,
    message: messageVal
  } = validations

  const [loading, setLoading] = useState(true)
  const [eligibility, SetEligibility] = useState([])
  const PUBLIC_URL = process.env.REACT_APP_APIURL

  useEffect(() => {
    http.get('api/requirement/ApiRequirementList').then(res => {
      if (res.data.status === 200) {
        SetEligibility(res.data.requirement)
        console.log(res.data.requirement)
        setLoading(false)
      }
    })
  }, [])

  return (
    <>
      <section className='become-teacher'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='become-teacher__content'>
                <h2 className='become-teacher__title'>
                  Be a part of the mission to raise global citizens of tomorrow
                </h2>
                <p className='become-teacher__text'>
                  Shanti Asiatic School Surat is CBSE affiliated, committed to
                  provide high-quality education to the students with indigenous
                  background. It is well-equipped with the most up-to-date
                  technology and possesses progressive environment for better
                  learning and development. Shanti Asiatic School Surat is
                  regularly looking for admin team and teachers with a vibrant
                  personality, extended subject knowledge, and fluency in
                  English.
                </p>
                {/* <h2 className="become-teacher__subtitle">Health and
                          Pension</h2>
                      <p className="become-teacher__text">Lorem Ipsum has been the standard dummy text ever since
                          the when an
                          unknown was popularised. It has survived not only five centuries. but also the leap into
                          electronic typesetting remaining unchanged.</p>
                      <h2 className="become-teacher__subtitle">Vacation
                          Time</h2>
                      <p className="become-teacher__text">Lorem Ipsum has been the standard dummy text ever since
                          the when an
                          unknown was popularised. It has survived not only five centuries. but also the leap into
                          electronic typesetting remaining unchanged.</p> */}
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='become-teacher__form'>
                <div className='become-teacher__form-top'>
                  <h2 className='become-teacher__form-title'>Apply Now !</h2>
                </div>
                <Formik>
                  <Form
                    id='contactform'
                    onSubmit={handleSubmit}
                    onBlur={validateOne}
                    className='become-teacher__form-content contact-form-validated'
                    noValidate='novalidate'
                    encType='multipart/form-data'
                  >
                    <input
                      type='text'
                      placeholder='Your Name'
                      name='fullname'
                      autoComplete='off'
                      value={fullname}
                      onChange={handleChange}
                    />
                    <div className='error'>{fullnameVal}</div>
                    <input
                      type='email'
                      placeholder='Email Address'
                      name='email'
                      autoComplete='off'
                      value={email}
                      onChange={handleChange}
                    />
                    <div className='error'>{emailVal}</div>
                    <input
                      type='number'
                      placeholder='Phone Number'
                      name='phone_number'
                      autoComplete='off'
                      value={phone_number}
                      onChange={handleChange}
                    />
                    <div className='error'>{numberVal}</div>
                    <input
                      type='text'
                      placeholder='Comment'
                      name='message'
                      autoComplete='off'
                      value={message}
                      onChange={handleChange}
                    />
                    <div className='error'>{messageVal}</div>
                    <input
                      type='file'
                      name='pdf'
                      class='p-3'
                      onChange={fileChanges}
                    />
                    <button
                      type='submit'
                      className='thm-btn become-teacher__form-btn'
                    >
                      Apply For It
                    </button>
                  </Form>
                </Formik>
                <div className='result text-center'></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='contact-one dup'>
        <div className='container'>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Open Position</th>
                <th>Eligibility/Requirement</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={2}>
                    <h4>View Requirement list...</h4>
                  </td>
                </tr>
              ) : (
                <>
                  {eligibility.map((item, i) => (
                    <tr key={i}>
                      <td>{item.position}</td>
                      <td>{item.eligibility}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
          <div className='result text-center'></div>
        </div>
      </section>

      <section className='become-teacher mb-2'>
        <div className='cta-two'>
          <div className='container-fluid'>
            <div className='row no-gutters'>
              <div className='col-lg-6 thm-base-bg'>
                <div className='cta-two__single'>
                  <div className='cta-two__icon'>
                    <span>
                      <i className='kipso-icon-professor' />
                    </span>
                  </div>
                  <div className='cta-two__content'>
                    <h2 className='cta-two__title'>Become an teacher</h2>
                    <p className='cta-two__text'>
                      Be an Educator with SAS and join our mission to raise
                      children who will be tomorrows leading scientists,
                      business tycoon, Start-up Founder, Doctor or a leader.
                    </p>
                    <a href='#contactform' className='thm-btn cta-two__btn'>
                      Apply
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 thm-base-bg-2'>
                <div className='cta-two__single'>
                  <div className='cta-two__icon'>
                    <span>
                      <i className='kipso-icon-knowledge' />
                    </span>
                  </div>
                  <div className='cta-two__content'>
                    <h2 className='cta-two__title'>Join our community</h2>
                    <p className='cta-two__text'>
                      Join SAS in its journey to become a premier education
                      institution. We look forward to have you on board !
                    </p>
                    <a href='#contactform' className='thm-btn cta-two__btn'>
                      Apply
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default CareersAtSAS
