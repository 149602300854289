// const $ = window.$;

const closeLoader = () => {
  setTimeout(() => {
    // $(".preloader").hide();
  }, 500);
}

const FileUpload = (url, formdata) => {
  return new Promise((resolve, reject) => {
    try {
      // $(".preloader").show();
      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
          method: "POST",
          body: formdata,
          redirect: 'follow'
        })
        .then(response => response.json())
        .then(json => {
          resolve(json);
          closeLoader();
        })
        .catch(error => {
          reject(error);
          closeLoader();
        });
    } catch (e) {
      reject(e);
      closeLoader();
    }
  });
}



const Post = (url, data) => {
  return new Promise((resolve, reject) => {
    try {
      // $(".preloader").show();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
          resolve(json);
          closeLoader();
        })
        .catch(error => {
          reject(error);
          closeLoader();
        });
    } catch (e) {
      reject(e);
      closeLoader();
    }
  });
}

const Get = (url, data) => {
  return new Promise((resolve, reject) => {
    try {
      // $(".preloader").show();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(process.env.REACT_APP_APIURL + "api/" + url, {
          method: "GET",
          headers: myHeaders,
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
          resolve(json);
          closeLoader();
        })
        .catch(error => {
          reject(error);
          closeLoader();
        });
    } catch (e) {
      reject(e);
      closeLoader();
    }
  });
}

export {
  Post,
  FileUpload,
  Get,
  closeLoader
};