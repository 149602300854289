import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import CareersAtSAS from '../components/CareersAtSAS';
import Topbar from '../components/Topbar';

const CareersAtSASPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Careers">
            <Topbar />
            <NavOne />
            <PageHeader title="Careers At SAS" />
            <CareersAtSAS />
            <Footer />
        </Layout>
    );
};

export default CareersAtSASPage;
